import { NavLink, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import appLinks from "app/constants/appLinks";
import { useAppTheme } from "app/providers/AppThemeProvider";

export default function DashboardMobilenav() {
  const theme = useAppTheme();

  // const profile = useProfile();

  const location = useLocation();

  // if (!profile) return null;

  // const links = profile.merchant ? appLinks.concat(merchantLinks) : appLinks;

  return (
    <Container>
      {appLinks.map(({ label, icon: Icon, url, children }) => {
        const subNavActive = (children ?? []).some((r) =>
          location.pathname.startsWith(r.url)
        );

        return (
          <Link key={label} to={url} className={clsx({ subNavActive })}>
            <Icon className={theme} /> {label}
          </Link>
        );
      })}
    </Container>
  );
}

const Container = styled.nav`
  ${tw`w-full h-full flex border-t px-[8px]`};

  ${tw`bg-white `};

  ${tw`dark:bg-black dark:border-t-white12`};
`;

const Link = styled(NavLink)`
  ${tw`w-[60px] h-full flex-shrink-0 flex-grow flex flex-col items-center justify-center text-base text-black32`};

  ${tw`dark:text-white32`};

  svg {
    ${tw`w-[24px] h-[24px] mb-[4px]`};
  }

  &.active,
  &.subNavActive {
    ${tw`text-black`};

    ${tw`dark:text-white`};

    svg {
      path {
        fill: transparent;
        fill-opacity: 1;
      }

      &.dark {
        path {
          fill: transparent;
          fill-opacity: 1;
        }
      }
    }
  }

  &:nth-of-type(3) {
    &.active,
    &.subnav-active {
      svg {
        path {
          fill: #000;
          fill-opacity: 1;
        }

        &.dark {
          path {
            fill: #fff;
            fill-opacity: 1;
          }
        }
      }
    }
  }
`;
